import { Box, Button, FormControl, Autocomplete , FormHelperText, InputLabel, MenuItem,  Select, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React, { useState } from "react"; 
const styles = require("../styles/signup.module.css");
import { countries } from "../constants/countrycode";

const OnBoarding = (props) => {
    const validate = ( values : any) => {
        let errors = {};
        if (values.fname === "") {
            Object.assign(errors, {["fname"]: "Name cannot be empty"});
        }
        if (values.country === "") {
            Object.assign(errors, {["country"]: "Country cannot be empty"});
        }
        if (values.industry === "") {
            Object.assign(errors, {["industry"]: "This field cannot be empty"});
        }
        return errors;
    };

    return (
        <div className = {styles.onBoardBodyCont}>
            <Formik
                initialValues={{
                    email: props && props.email && props.email != "" ? props.email : "",
                    fname: props && props.userName && props.userName != "" ? props.userName : "",
                    country: "", 
                    others: "", 
                    industry: "",
                }}
                validate={(initialValues) => validate(initialValues)} 
                onSubmit={async (values: any) => {
                    props.onSubmit({values})
                }}>

                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched, 
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box my={3}>
                            <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label="Email"
                                margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="standard"
                            />
                            <TextField
                                error={Boolean(touched.fname && errors.fname)}
                                fullWidth
                                helperText={touched.fname && errors.fname}
                                label="Username *"
                                margin="normal"
                                name="fname"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.fname}
                                variant="standard"
                            />
                            <Autocomplete
                                id="country-select-demo"
                                style={{ width: 300 , color: "#FFF"}}
                                options={countries}
                                className = {styles.cls_CountrySelect}
                                classes={{
                                    option: styles.cls_CountryOpt,
                                }}
                                onChange={(event, newValue) => {
                                    values.country = (newValue != null) ? newValue : "";
                                }}
                                
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Choose a country"
                                    variant="standard"
                                    error={Boolean(touched.country && errors.country)}
									helperText={touched.country && errors.country}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    />
                                )}
                            />
                            <FormControl 
                                variant="standard" 
                                fullWidth 
                                className = {styles.cls_IndustrySelect}
                                >
                                <InputLabel id="Industry" error={Boolean(touched.industry && errors.industry)}>Industry *</InputLabel>
                                <Select
                                labelId="Industry"
                                id="Industry-select"
                                value={values.industry}
                                onBlur={handleBlur}
                                error={Boolean(touched.industry && errors.industry)}
                                onChange={(opt, e) => {
                                    handleChange("industry")(opt.target.value);
                                }}
                                
                                >
                                    <MenuItem value="IT">IT</MenuItem>
                                    <MenuItem value="Banking">Banking</MenuItem>
                                    <MenuItem value="E-commerce">E-commerce</MenuItem>
                                </Select>
                                { 
                                    touched.industry && errors.industry && 
                                    <FormHelperText style = {{color: "#f44336"}}>Industry cannot be empty</FormHelperText> 
                                }
                            </FormControl>
                            {/* {
                                industry === "others" && 
                                <TextField
                                    error={Boolean(touched.others && errors.others)}
                                    fullWidth
                                    helperText={touched.others && errors.others}
                                    label="Others (Please specify)"
                                    margin="normal"
                                    name="others"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.others}
                                    variant="standard"
                                />
                            } */}
                        </Box>
                        <Box my={3} style = {{textAlign : "center"}}>
                            <Button
                                className = {styles.signUpBtn}
                                color="primary"
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </div>
        
    )
}

export default OnBoarding; 

