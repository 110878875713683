import React, { useContext, useState } from "react"; 
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik } from "formik";
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import * as Yup from "yup";
import OnBoarding from "../components/onboarding";
import axios from "axios";
import { API_URL, GAPI_CLIENT_ID, GITHUB_CLIENT_ID } from "../constants/constants";
import { toast } from "react-toastify";
import { navigate } from 'gatsby';
import 'react-toastify/dist/ReactToastify.css';
import GitHubLogin from 'react-github-login';
import GoogleLogin from "react-google-login";
import Layout from "../components/Layout";
const styles = require("../styles/signup.module.css");
import { useLocation } from '@reach/router';
import TeamContext from "../context/TeamContextProvider";
import { requestHandler } from "../utils/utils";


const SignUp = () => {
    const [view , setView] = useState("terms");
    const [accType , setAccType] = useState("individual");
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordStrength , setPasswordStrength] = useState("none");
    const { setUserData } = useContext(TeamContext);
    const location = useLocation();
    const [userDetails , setUserDetails ] = useState({
        "email": "",
        "password": "",
        "user_name": "",
        "country": "",
        "organisation": "",
        "industry": "",
        "google_account_id": "",
        "github_account_id": "",
        "smitch_account_id": "",
        "login_type": "email"
    })

    const onSuccess = async response => {
        // console.log("Success", response);
        const data = {
            ...userDetails, 
            "login_type": "github", 
            "github_account_id": response.code
        }
        setUserDetails(data);
        setView("onBoarding"); 
    }
    const onFailure = error => console.error("failed"); 
    const onRequest = () => console.log("onRequest");

    const callDevAPI = async (access_token) => {
        const result = await requestHandler(API_URL + "developer/" , null, { "Content-Type": "application/json" , "access_token": access_token}, "GET")
        if(result.status === "success") 
        {
            // toast.success(result.message); 
            const payload = {
                "name": result.data.user_name, 
                "email": result.data.email, 
                "team_id": result.data.teams[0].team.team_id, 
                "teamName": result.data.teams[0].team.name
            }
            setUserData(JSON.stringify(payload));
            navigate('/user/apps');
        }
        if(result.status === "failed") 
        {
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const responseGoogle = async (response: any) => {
        // console.log("responseGoogle", response); 
        const profileObj = response?.profileObj;
        if (profileObj != null) {
            const { email, googleId, name } = profileObj;
            const data = {
                ...userDetails, 
                "login_type": "google", 
                "google_account_id": googleId, 
                "email": email, 
                "user_name": name
            }
            setUserDetails(data);
            setView("onBoarding"); 
        }
    };

    let icon:any ;
    if (passwordShown ==true) {
        icon = <Visibility style = {{cursor: "pointer"}} onClick={() => togglePasswordVisiblity()} />;
    } else if (passwordShown == false) {
        icon = <VisibilityOff style = {{cursor: "pointer"}}  onClick={() => togglePasswordVisiblity()} />;
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const callSignUp = async (data:any) => {
        try {
            const headers = {
                'Access-Control-Allow-Origin': 'true'
            }
              
            const result:any = await axios.post(API_URL + "developer/signup", data , {headers: {
                'Access-Control-Allow-Origin': 'true'
            }})
            if(result.data.status === "success") 
            {
                callDevAPI(result.data.data.access_token);
                toast.success(result.data.message); 
                localStorage.setItem("isLoggedIn", "true");
                localStorage.setItem("access_token", result.data.data.access_token);
                localStorage.setItem("refresh_token", result.data.data.refresh_token);
            }
            if(result.status === "failed") 
            {
                toast.error(result.errorMessage); 
            }
                
        }
        catch (error) {
            // Sentry.captureException(error);
            toast.error(error.response.data.errorMessage); 
            console.log("error", error.response.data.errorMessage);
        }
    }

    const handleOnBoardSubmit =  (params: any = {}) => {
        const data = {
            ...userDetails, 
            email: params.values.email,
            user_name: params.values.fname, 
            country: params.values.country, 
            industry: params.values.industry
        }
        setUserDetails(data); 
        callSignUp(data);
    }

    const handleRadioChange = (event) => {
        setAccType(event.target.value);
    }

    return(
        <Layout>
            <div className = {styles.cls_SignupPageWrap}>
                <div className = {styles.cls_SignupPage}>
                    <div className = {styles.cls_SignupHeader}>
                        <div className = {styles.cls_SignupHeaderLogo}>
                            <img
                            src="https://d199xmsg3owom4.cloudfront.net/images/smitch_logo_white.png"
                            alt="SMITCH logo" height="80" width="80" />
                        </div>
                        <div className = {styles.cls_SignupTitle}>Create your Smitch Developer Account</div>
                    </div>
                    {
                        view === "terms" && 
                        <div className = {styles.cls_SignUpTermsBody}>
                            <div className = {styles.cls_SignupTermsText}>
                                To continue, read and agree to the Terms of Use and Privacy Policy.
                            </div>
                            <div className = {styles.cls_SignUpAgreeBtn} onClick = {() => setView("signup")}>
                                Agree
                            </div>
                        </div>
                    }
                    {
                        view === "signup" && 
                        <div className = {styles.cls_SignUpBasicWrapper}>
                            <div className = {styles.cls_SignUpAccTypeWrap}>
                                <FormControl component="fieldset" className = {styles.cls_AccTypeWrap}>
                                    <RadioGroup row aria-label="account type" name="accountType" value={accType} onChange={handleRadioChange} >
                                        <FormControlLabel value="individual" className = {styles.cls_AccTypeRadioGrp}  control={<Radio color="primary" />} label="Individual" />
                                        <FormControlLabel value="organisation" className = {styles.cls_AccTypeRadioGrp} control={<Radio color="primary"/>} label="Organisation" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            {
                                accType === "individual" && 
                                <>
                                <Formik
                                    initialValues={{
                                        password: "",
                                        email: "", 
                                    }}
                                    // validate={(initialValues) => validate(initialValues)}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                            .email("Must be a valid email")
                                            .max(255)
                                            .required("Email is required"),
                                        password: Yup.string()
                                            .max(255)
                                            .matches(
                                                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                                                "Password must contain at least 8 characters, one uppercase, one number and one special case character"
                                            )
                                            .required("Password is required"),
                                    })}
                                    onSubmit={async (values: any) => {
                                        let data = {
                                            ...userDetails, 
                                            email: values.email, 
                                            password: values.password
                                        }
                                        setUserDetails(data);
                                        setView("onBoarding");
                                    }}>

                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        touched, 
                                        values,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box my={3}>
                                                <TextField
                                                    error={Boolean(touched.email && errors.email)}
                                                    fullWidth
                                                    helperText={touched.email && errors.email}
                                                    label="Email Address"
                                                    margin="normal"
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="email"
                                                    value={values.email}
                                                    variant="standard"
                                                />
                                                <TextField
                                                    error={Boolean(touched.password && errors.password)}
                                                    fullWidth
                                                    helperText={touched.password && errors.password}
                                                    label="Password"
                                                    margin="normal"
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type={passwordShown ? "text" : "password"}
                                                    value={values.password}
                                                    variant="standard"
                                                    InputProps={{
                                                        endAdornment: icon
                                                    }}
                                                />
                                            </Box>
                                            <Box my={4} style = {{textAlign: "center"}}>
                                                <Button
                                                    className = {styles.signUpBtn}
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    Sign Up
                                                </Button>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                                <div className = {styles.indSocialLoginWrapper}>
                                    <div className = {styles.indSocialLoginText}>
                                        Or Continue with Social Login
                                    </div>
                                    <div className = {styles.indSocialLoginCont}>
                                        <GoogleLogin
                                            clientId={GAPI_CLIENT_ID}
                                            buttonText="Login using Google"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                            render={(props: any) => (
                                                <img src = "../Google@3x.png"height="40" width="40" onClick={props.onClick} className = {styles.socialLoginImg} />
                                            )}
                                        />
                                        <GitHubLogin clientId={GITHUB_CLIENT_ID}
                                            className={styles.GitHubLogin}
                                            valid={true}
                                            redirectUri={location.origin + "/login"}
                                            onRequest={onRequest}
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}>
                                                <img src = "../github.png" height="40" width="40" />
                                        </GitHubLogin>
                                        {/* <img src = "../Smitch.png" height="40" width="40" className = {styles.socialLoginImg}/> */}
                                    </div>
                                </div>
                                </>
                            }
                            {
                                accType === "organisation" && 
                                <>
                                <Formik
                                    initialValues={{
                                        password: "",
                                        workemail: "", 
                                    }}
                                    // validate={(initialValues) => validate(initialValues)}
                                    validationSchema={Yup.object().shape({
                                        workemail: Yup.string()
                                            .email("Must be a valid email")
                                            .matches(
                                                /^((?!gmail|yahoo|hotmail).)*$/,
                                                "Please enter your work email address"
                                            )
                                            .max(255)
                                            .required("Email is required"),
                                        password: Yup.string()
                                            .max(255)
                                            .matches(
                                                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                                                "Password must contain at least 8 characters, one uppercase, one number and one special case character"
                                            )
                                            .required("Password is required"),
                                    })}
                                    onSubmit={async (values: any) => {
                                        let data = {
                                            ...userDetails, 
                                            email: values.workemail, 
                                            password: values.password
                                        }
                                        setUserDetails(data);
                                        setView("onBoarding");
                                    }}>

                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        touched, 
                                        values,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box my={3}>
                                                <TextField
                                                    error={Boolean(touched.workemail && errors.workemail)}
                                                    fullWidth
                                                    helperText={touched.workemail && errors.workemail}
                                                    label="Work Email"
                                                    margin="normal"
                                                    name="workemail"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="email"
                                                    value={values.workemail}
                                                    variant="standard"
                                                />
                                                <TextField
                                                    error={Boolean(touched.password && errors.password)}
                                                    fullWidth
                                                    helperText={touched.password && errors.password}
                                                    label="Password"
                                                    margin="normal"
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type={passwordShown ? "text" : "password"}
                                                    value={values.password}
                                                    variant="standard"
                                                    InputProps={{
                                                        endAdornment: icon
                                                    }}
                                                />
                                            </Box>
                                            <Box my={4} style = {{textAlign: "center"}}>
                                                <Button
                                                    className = {styles.signUpBtn}
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    Sign Up
                                                </Button>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                                <div className = {styles.indSocialLoginWrapper}>
                                    <div className = {styles.indSocialLoginText}>
                                        Or Continue with Social Login
                                    </div>
                                    <div className = {styles.indSocialLoginCont}>
                                        <GoogleLogin
                                            clientId={GAPI_CLIENT_ID}
                                            buttonText="Login using Google"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                            render={(props: any) => (
                                                <img src = "../Google@3x.png"height="40" width="40" onClick={props.onClick} className = {styles.socialLoginImg} />
                                            )}
                                        />
                                        <GitHubLogin clientId={GITHUB_CLIENT_ID}
                                            className={styles.GitHubLogin}
                                            valid={true}
                                            redirectUri={location.origin + "/login"}
                                            onRequest={onRequest}
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}>
                                                <img src = "../github.png" height="40" width="40" />
                                        </GitHubLogin>
                                        {/* <img src = "../Smitch.png" height="40" width="40" className = {styles.socialLoginImg}/> */}
                                    </div>
                                </div>
                                </>
                            }
                        </div>
                    }
                    {
                        view === "onBoarding" && 
                        <div className = {styles.cls_SignUpOnBoardWrapper}>
                            <OnBoarding userName = {userDetails.user_name} onSubmit = {(params) => handleOnBoardSubmit(params)} email = {userDetails.email}/>
                        </div>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default SignUp;