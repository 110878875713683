// extracted by mini-css-extract-plugin
export const cls_SignupPage = "signup-module--cls_SignupPage--gmPzq";
export const cls_SignupHeaderLogo = "signup-module--cls_SignupHeaderLogo--IXUYQ";
export const cls_SignupTitle = "signup-module--cls_SignupTitle--OHy_c";
export const cls_SignUpTermsBody = "signup-module--cls_SignUpTermsBody--3F1Ld";
export const cls_SignupTermsText = "signup-module--cls_SignupTermsText--2uNWo";
export const cls_SignUpAgreeBtn = "signup-module--cls_SignUpAgreeBtn--342IS";
export const signUpBtn = "signup-module--signUpBtn--hyqhI";
export const cls_SignUpAccTypeWrap = "signup-module--cls_SignUpAccTypeWrap--2JRKI";
export const cls_AccTypeRadioGrp = "signup-module--cls_AccTypeRadioGrp--2w2nR";
export const cls_IndustrySelect = "signup-module--cls_IndustrySelect--XunHn";
export const indSocialLoginCont = "signup-module--indSocialLoginCont--33h6_";
export const signUpWrapper = "signup-module--signUpWrapper--116Sg";
export const indSocialLoginText = "signup-module--indSocialLoginText--2BP8L";
export const socialLoginImg = "signup-module--socialLoginImg--T8WqE";
export const GitHubLogin = "signup-module--GitHubLogin--2S8sI";
export const indSocialLoginWrapper = "signup-module--indSocialLoginWrapper--1q3xs";
export const cls_ErrorText = "signup-module--cls_ErrorText--3_Nij";
export const cls_CountrySelect = "signup-module--cls_CountrySelect--1VVNF";
export const cls_CountryOpt = "signup-module--cls_CountryOpt--3-Y_D";